import './App.css';

function App() {
  return (
    <div className="App">
    <>
  <div className="row">
    <div className="col-md-6 col-centered headline center-container">
      <img
        src="./images/tokoya.jpeg"
        alt="tokoya logo"
        style={{ maxWidth: "100%", maxHeight: "13rem" }}
      />
    </div>
  </div>
  <div className="row spacer">
    <div className="col-md-6 col-centered">
      <div className="col-centered">
        <p className="intro">A barbershop in Heeley, Sheffield, S8 0XQ</p>
        <p> </p>
      </div>
    </div>
  </div>
  <div className="row spacer">
    <div className="col-md-6 col-centered">
      <div className="col-centered">
        <p className="price-th">Hours</p>
        <div style={{ float: "left", margin: 0, width: "50%" }}>
          <p className="hours-days">Mon-Fri</p>
          <p className="hours">10→7</p>
          <p />
        </div>
        <div style={{ float: "right", margin: 0, width: "50%" }}>
          <p className="hours-days">Sat</p>
          <p className="hours">9→3</p>
          <p />
        </div>
        <p />
      </div>
    </div>
  </div>
  <div className="row spacer">
    <div className="col-md-6 col-centered">
      <div className="col-centered footer-tr">
        <table className="prices-table">
          <thead className="price-th">
            <tr className="price-tr">
              <th colSpan={1} style={{ fontWeight: 400 }}>
                Services
              </th>
              <p/>
            </tr>
          </thead>
          <tbody>
            <tr className="price-tr">
              <td className="td-label dashed">Haircut</td>
              <td className="td-cost dashed">£ 22.50</td>
            </tr>
            <tr className="price-tr">
              <td className="td-label dashed">Haircut and beard trim</td>
              <td className="td-cost dashed">£ 25.00</td>
            </tr>
            <tr className="price-tr">
              <td className="td-label dashed">Kids haircut</td>
              <td className="td-cost dashed">£ 18.50</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <a
          className="book-action"
          href="https://booksy.com/en-gb/23007_tokoya_barber-shop_1419956_sheffield"
          style={{ float: "right", paddingTop: 25 }}
        >
          Book service
        </a>
      </div>
    </div>
  </div>
</>

    </div>
  );
}

export default App;
